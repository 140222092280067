@mixin include-vollkorn($font-weight) {
  $path: "../../public/g-fonts/Vollkorn-" + #{$font-weight};
  font-display: swap;
  /* stylelint-disable */
  font-family: "Vollkorn";
  /* stylelint-enable */
  font-style: normal;
  font-weight: $font-weight;
  src:
    url($path + ".woff2") format("woff2"),
    url($path + ".woff") format("woff"),
    url($path + ".ttf") format("truetype");
}

@font-face {
  @include include-vollkorn(400);
}

@font-face {
  @include include-vollkorn(600);
}

@font-face {
  @include include-vollkorn(700);
}

@font-face {
  @include include-vollkorn(800);
}

@mixin include-inter($font-weight) {
  $path: "../../public/g-fonts/Inter-" + #{$font-weight};
  font-display: swap;
  /* stylelint-disable */
  font-family: "Inter";
  /* stylelint-enable */
  font-style: normal;
  font-weight: $font-weight;
  src:
    url($path + ".woff2") format("woff2"),
    url($path + ".woff") format("woff"),
    url($path + ".ttf") format("truetype");
}

@for $i from 3 through 8 {
  @font-face {
    @include include-inter($i * 100);
  }
}

@mixin include-restora($font-weight) {
  $path: "../../public/external-fonts/restora-" + #{$font-weight};
  font-display: swap;
  /* stylelint-disable */
  font-family: "Restora";
  /* stylelint-enable */
  font-style: normal;
  font-weight: $font-weight;
  src:
    url($path + ".woff2") format("woff2"),
    url($path + ".woff") format("woff"),
    url($path + ".ttf") format("truetype");
}

@for $i from 1 through 8 {
  @font-face {
    @include include-restora($i * 100);
  }
}
